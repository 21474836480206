.parent-cart {
  /* position: absolute;
  right: 10px;
  top: 20px; */
  /* min-height: 20vh;
  max-height: 120vh; */

  /* height: 475px; */

  /* width: 400px; */

  background-color: white;
  margin-top: 20px;
  /* overflow: scroll; */
}
.cartlist {
  overflow: scroll;
  min-height: 200px;
  max-height: 650px;
  height: 650px;
}
.cart-container {
  background-color: #9abc66;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.cart-container img {
  margin-right: 10px;
}

.quantity,
.total-price {
  margin-right: 10px;
  font-weight: bold;
}

.checkout-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkout-button:hover {
  background-color: #0056b3;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

h2 {
  text-align: center;
  font-weight: bolder;
}

.ksGyxT {
  background-color: rgb(255, 255, 255);
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 12px 12px 0px,
    rgba(0, 0, 0, 0.12) 0px 0px 12px 0px;
  position: relative;
  margin-top: 20px;
}

.sNUgR {
  padding: 16px 16px 0px;
}
.gejECp {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.bCvzDL {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-transform: none;
  color: rgb(38, 38, 38);
}

.eJPBuz {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
  color: rgb(38, 38, 38);
}

.zbTqY {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px 16px 16px;
}

.cRkVpC {
  font-size: 16px;
}

.hEyZIb {
  /* background-color: rgb(27, 171, 30); */
  background-color: #0B577E;
}

.jPQkWg {
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  line-height: 2.43;
  width: 100%;
  padding: 0px 12px;
  min-width: 60px;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  /* background-color: rgb(2, 108, 223); */
  border: 1px solid transparent;
  transition: background-color 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
}

.card-container {
  margin-top: 90vh;
}
