.parent-checkout {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
   
    /* width: 70vw; */
    padding-left: 40px;
    padding-right: 40px;
    margin: 0px auto;


}