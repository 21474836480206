.h1 {
  color: #f79696;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
  text-align: center;
}

p {
  color: #404f5e;
  font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
  margin: 0;
  text-align: center;
}

.checkmark {
  color: #f79696;
  font-size: 100px;
  line-height: 200px;
  display: flex;
  justify-content: center;
}

.card {
  background: white;
  padding: 60px;
  border-radius: 4px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: inline-block;
  margin: 0 auto;
  width: 455px;
}

.circle {
  border-radius: 200px;
  height: 200px;
  width: 200px;
  background: #f8faf5;
  margin: 0 auto;
}

.failed-card-container {
  display: flex;
  justify-content: center;
  margin-top: 15rem !important;
}
