.ffKjVh {
  position: relative;
  margin: 25px;
  white-space: normal;
  background-color: rgb(255, 255, 255);
  /* border: 1px solid rgb(227, 228, 229); */
}

.ticket-bag--exposed {
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 0;
  visibility: visible;
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.tooltip__srs {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.tooltip__nudge{
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Poppins,sans-serif;
  color: #000;
  box-sizing: inherit;
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
  filter: drop-shadow(0 2px 12px rgba(0,0,0,.03));
  top: -6px;
  margin-right: 3px;
  border-top-width: 0;
  border-bottom-color: #e6ebf5;
  left: 274.5px;
}

.tooltip__content {
  padding: 15px;
  font-size: 1.6rem;
  line-height: 1.4;
  height: 10px;
}

.split-content {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 26px;
}
.ticket-bag__ticket-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.4rem;
  font-weight: bold;
}

.split-content__primary {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
}
.srs__set {
  /* display: flex;
  flex-direction: column; */
  -webkit-box-flex: 1;
  /* -ms-flex: 1 1 auto; */
  /* flex: 1 1 auto; */
  text-align: center;
}
.srs__label {
  font-size: .83rem;
  line-height: 1;
  font-weight: normal;
  color: #727272;
  text-transform: uppercase;
  display: block;
  margin-bottom: 1px;
 
}
.srs__value {
  font-size: .9rem;
  margin-top: 2px;
  line-height: 1;
  display: block;
  font-weight: bold;
  color: #262626;
 
}

.fuacRE {
  display: flex;
  flex-direction: row;
}
.ticket-bag__ticket-price {
  max-width: 60%;
  text-align: right;
}

.ticket-bag__ticket-description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 1.1rem;
  font-weight: bold;
}

.gbMHqu {
  display: flex;
  flex-direction: column;
}

.ticket-bag__ticket-offer-price {
  font-size: .9rem;
}

.ant-card .ant-card-body{
  padding: 0px;
}


element.style {
}
svg:not(:root) {
    overflow: hidden;
}
svg:not(:root) {
    overflow: hidden;
}

.hJThSn {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: rgb(255, 255, 255);
    transform: rotate(45deg);
    cursor: pointer;
}